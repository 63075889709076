import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Logo from '../../images/logo.svg';


const Header = (props) => {
    const { title } = props;

    return (
        <div className={`py-11.25`}>
            <div className={`header-dash flex flex-row items-center justify-between`}>
                <div className={`flex-none`}>
                    <Link to="/">
                        <img src={Logo} alt="Bouygues"/>
                    </Link>
                </div>
                <div className={`flex-1 text-right pr-6`}>
                    { title }
                </div>
            </div>
        </div>
    )
}

Header.propTypes = {
    title: PropTypes.node.isRequired,
}

export default Header
