import * as React from "react"

export const TimeIco = (props) => {
    const { color, classes } = props;

    return (
        <svg className={classes} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle fill={color} cx="16" cy="16" r="16"/>
            <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M16 25C11.0294 25 7 20.9706 7 16C7 11.0294 11.0294 7 16 7C20.9706 7 25 11.0294 25 16C25 20.9706 20.9706 25 16 25Z"/>
            <path fill={color} fillRule="evenodd" clipRule="evenodd" d="M15.9631 10.5H16.0476C16.3044 10.5 16.5195 10.6946 16.5451 10.9502L17.0001 15.5L20.2481 17.356C20.4039 17.4451 20.5001 17.6107 20.5001 17.7902V18C20.5001 18.2109 20.3291 18.3819 20.1182 18.3819C20.0842 18.3819 20.0504 18.3774 20.0177 18.3684L15.3987 17.1087C15.1674 17.0456 15.0134 16.8271 15.0318 16.588L15.4646 10.9617C15.4846 10.7012 15.7018 10.5 15.9631 10.5Z"/>
        </svg>
    )
}

export const MarkerIco = (props) => {
    const { color, classes } = props;

    return (
        <svg className={classes} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle fill={color} cx="16" cy="16" r="16"/>
            <path fill="white" d="M8 14.7081C8 10.0832 11.2 7 16 7C20.8 7 24 10.854 24 14.7081C24 17.1053 21.6786 20.3973 17.0357 24.584C16.4431 25.1183 15.5291 25.1405 14.9093 24.6355C10.3031 20.8826 8 17.5734 8 14.7081ZM16 16.2497C17.4728 16.2497 18.6667 15.0993 18.6667 13.6803C18.6667 12.2613 17.4728 11.111 16 11.111C14.5272 11.111 13.3333 12.2613 13.3333 13.6803C13.3333 15.0993 14.5272 16.2497 16 16.2497Z"/>
        </svg>
    )
}