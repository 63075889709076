import * as React from "react"
import PropTypes from "prop-types"


export const ConfRow = (props) => {
    const { children, classes } = props;

    return (
        <div className={`font-bryant font-bold tracking-0.275 inline-flex flex-row items-center ${classes}`}>
            {children}
        </div>
    )
}

export const ConfItem = (props) => {
    const { classes, picto, text } = props;

    return (
        <div className={`flex items-center ${classes}`}>
            { picto }
            <span className={`flex-1`}>{ text }</span>
        </div>
    )
}


const Conf = (props) => {
    const { children, classes } = props;

    return (
        <div className={`${classes ? classes : ''}`}>
            {children}
        </div>
    )
}

Conf.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
}


export default Conf
